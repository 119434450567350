import React from 'react';
import { PageProps } from 'gatsby';
import { ReviewsPage as Page } from 'features/homepage';
import type { Locale } from 'features/localization';

function ReviewsPage({ pageContext: { locale } }: PageProps<unknown, { locale: Locale }>) {
  return <Page locale={locale} />;
}

export default ReviewsPage;
